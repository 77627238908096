export const hardcodedCrests = [
  { id: 999, label: "Default", crest: "" },
  { id: 1003, label: "Premier League", crest: ":premierleague:" },
  { id: 1002, label: "Champions League", crest: ":championsleague:" },
  { id: 1001, label: "Championship", crest: ":championship:" },
  { id: 1004, label: "Fantasy", crest: ":fantasy:" },
  { id: 1006, label: "Full Time Result", crest: ":ftresult:" },
  { id: 1007, label: "Goal Scorer", crest: ":goalscorer:" },
  { id: 1008, label: "Both Teams To Score", crest: ":btts:" },
  { id: 1009, label: "Builder", crest: ":builder:" },
  { id: 1005, label: "Football", crest: ":football:" },
];

export const hardcodedPayoutRatio = [
  {
    id: 0,
    label: "(deprecated) Welcome To Betmate",
    crest: "11.5-9-8.5-8-7.5-7-6.5-6-5.5-5-4.5-4-3.5-3-2.5-2-2-1.5-1.5-1",
  },
  {
    id: 1,
    label: "Fantasy £2/£200 100x (Pays 10)",
    crest: "35-22-14-8-6-5-4-3-2-1",
  },
  {
    id: 2,
    label: "Fantasy £3/£300 100x (Pays 20)",
    crest:
      "30-16.5-9.6-7-5-4-3.5-3-2.8-2.6-2.4-2.2-2-1.8-1.6-1.4-1.3-1.2-1.1-1",
  },
  {
    id: 3,
    label: "Fantasy £5/£400 80x (Pays 15)",
    crest: "25-18-12-9-7-5-4.5-4-3.5-3-2.5-2-1.75-1.5-1.25",
  },
  {
    id: 4,
    label: "Fantasy £5/£500 100x (Pays 25)",
    crest:
      "30-14-9.7-6.8-4.5-3.5-3-2.6-2.4-2.2-2-1.9-1.8-1.7-1.6-1.5-1.4-1.35-1.3-1.25-1.2-1.15-1.1-1.05-1",
  },
  {
    id: 5,
    label: "Fantasy £5/£600 120x (Pays 30)",
    crest:
      "26.4-13-8-5-4-3.25-2.8-2.6-2.4-2.3-2.2-2.1-2-1.9-1.8-1.7-1.65-1.6-1.55-1.5-1.45-1.4-1.35-1.3-1.25-1.2-1.15-1.1-1.05-1",
  },
];

export const hardcodedPayoutRatioSelector = [
  {
    id: 1,
    label: "FT Result £5/£2000 200x (Pays 10)",
    crest: "60-12-8-6-4-3-2.5-2-1.5-1",
  },
  {
    id: 2,
    label: "Goal Scorer £3/£200 66x (Pays 5)",
    crest: "50-20-15-10-5",
  },
  {
    id: 3,
    label: "Both Teams To Score £3/£200 66x (Pays 5)",
    crest: "50-20-15-10-5",
  },
];
